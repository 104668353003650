// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// images
import MapImage from 'images/mapsImages/belavista-index.png';

// Components
import SEO from 'components/seo';
import { device } from 'components/device';
import Layout from 'components/layout';
import BreadCrumb from 'components/breadCrumb';

import AddressTimes from 'components/units/unitPages/unit/addressTimes';
// import Downloads from 'components/unitPages/details/downloads'
import FeaturedMarketing from 'components/units/unitPages/unit/detailsFeaturedMarketing';
// import PublicTransportation from 'components/unitPages/details/publicTransportation';
import SocialShare from 'components/units/unitPages/details/socialShare';
import TextSection from 'components/units/unitPages/details/TextSection';
import DetailsTitle from 'components/units/unitPages/details/DetailsTitle';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};
  @media ${device.mobile} {
    padding: 0 20px 20px;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
`;

const Container = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-template-columns: repeat(12, 70px);
  column-gap: 30px;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderFeaturedMarketing = page => (
  <>
    <FeaturedMarketing
      markdown={getMarkdown(page, 'ckgi8sxd409d40a74q3b01itu', true)}
      servicesList={getMarkdown(page, 'ckh4uw05c6mnr0a30gquhvt9g', true)}
      asset={getMarkdown(page, 'ckh4uw05c6mnr0a30gquhvt9g', false, true)}
      isTitle
    />
  </>
);

const renderPage = (page, isDesktop, location) => (
  <GridContainer isDesktop={isDesktop}>
    <Part gridColumn={'2 / -2'}>
      <BreadCrumb
        details
        markdown={getMarkdown(page, 'ckhf8283c17820a12pl6loxbi', true)}
      />
      <DetailsTitle
        titleWidthMobile="14rem"
        markdown={getMarkdown(page, 'ckhexsyvs0a7o0a31xi0jfvs3', true)}
      />
    </Part>
    {!isDesktop && renderFeaturedMarketing(page)}
    <Container>
      <Part gridColumn={'1 / span 6'}>
        {isDesktop && renderFeaturedMarketing(page)}
        {/* About */}
        <TextSection
          markdown={getMarkdown(page, 'ckh4uz39c6mrp0a26u8j6a9my', true)}
          gridArea="ABT"
          isAbout
          isAboutMobile
          isShowing
          doNotApplyDisplayBlock
        />
        <TextSection //structure
          markdown={getMarkdown(page, 'ckh4v6mv46n4p0931qzesd5mr', true)}
          gridArea="STRUC"
          isAboutMobile
          isShowing
          doNotApplyDisplayBlock
          withoutBullets
          isRemoveMarginLi
        />
        {/* Chapel */}
        <TextSection
          markdown={getMarkdown(page, 'ckh4v9fy06nao0a30pcytiupk', true)}
          gridArea="CHA"
          isAboutMobile
          isShowing
          withoutBullets
          isRemoveMarginLi
        />
        {/* Services */}
        <TextSection
          markdown={getMarkdown(page, 'ckh4vj2e86ns10a30e27r4gai', true)}
          gridArea="SVC"
          isAboutMobile
          isShowing
        />
        {/* Alimentation */}
        <TextSection
          markdown={getMarkdown(page, 'ckhc7lvrk0eiz0b28tfdba7cy', true)}
          gridArea="ALI"
          isAboutMobile
          isShowing
          withoutBullets
          isRemoveMarginLi
        />
        {/* Helipad */}
        <TextSection
          markdown={getMarkdown(page, 'ckhav64awl7n30a265fv61l50', true)}
          gridArea="HEL"
          isAboutMobile
          isShowing
        />
        {/* Transfer Flow */}
        <TextSection
          markdown={getMarkdown(page, 'clmjabw2bdlh80bn8pnt7caur', true)}
          doNotApplyDisplayBlock
          gridArea="TRF"
          isAboutMobile
          isShowing
        />
        {/* Others Units Mobile */}
        <TextSection
          markdown={getMarkdown(page, 'ckiqror2g2eiw09711cbt9l0g', true)}
          isShort={true}
          gridArea="PAR"
          isAboutMobile
          isOthersUnits
        />
      </Part>
      <Part gridColumn={'8 / span 5'}>
        <AddressTimes
          markdown={getMarkdown(page, 'ckgif8zj40ps30a67meg44wfk', true)}
          fragments={getMarkdown(page, 'ckgif8zj40ps30a67meg44wfk')}
          isDetails
          location={{ lat: -23.5575613, lng: -46.6545421 }}
          zoom={16}
          mapPreview={MapImage}
          isStatic
          isDesktop={isDesktop}
        />
        {/* Schedule */}
        <TextSection
          markdown={getMarkdown(page, 'ckhbe215kn47v0a707vi4vpgm', true)}
          isShort={true}
          gridArea="SCH"
          isAboutMobile
          isShowing
        />

        {/* Parking */}
        <TextSection
          markdown={getMarkdown(page, 'ckhbe07kgn40q0a26074o0210', true)}
          isShort={true}
          gridArea="PAR"
          isAboutMobile
          isShowing
        />

        {/* <PublicTransportation
        markdown={getMarkdown(
          page,
          'ckhgi426w0cf40966insc2fca',
          true
        )}
        fragments={getMarkdown(page, 'ckhgi426w0cf40966insc2fca')}
        whichLine="BelaVista"
      /> */}

        {/* <Downloads /> */}

        {/* Others Units */}
        <TextSection
          markdown={getMarkdown(page, 'ckiqror2g2eiw09711cbt9l0g', true)}
          isShort={true}
          gridArea="PAR"
          isAboutMobile
          isOthersUnits
          isShowing
          isOthersUnitsMobile
        />

        {isDesktop && (
          <SocialShare
            title="Detalhes da Unidade Bela Vista"
            location={location}
          />
        )}
      </Part>
    </Container>
    {!isDesktop && (
      <SocialShare title="Detalhes da Unidade Bela Vista" location={location} />
    )}
  </GridContainer>
);

const BelaVistaDetails = ({ page, location }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckh4uw05c6mnr0a30gquhvt9g', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop, location)}
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckox6jzj463040b75z5puf1lk" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                    url
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <BelaVistaDetails
            page={response.gcms.site.pages}
            location={location}
          />
        );
      }}
    />
  );
};
